@keyframes shake {
	0%, 100% {
		transform: translate3d(0, 0, 0);
	}
	40%, 80% {
		transform: translate3d(-5px, 0, 0);
	}
	20%, 60% {
		transform: translate3d(5px, 0, 0);
	}
}

@keyframes shakeText {
	0%, 100% {
		text-indent: 0;
	}
	40%, 80% {
		text-indent: -5px;
	}
	20%, 60% {
		text-indent: 5px;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes shadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes shadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes slideIn {
	from {
		transform: translate3d(0, -300px, 0);
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideInMobile {
	from {
		transform: translate3d(0, 100%, 0);
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}


@keyframes slideOut {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		transform: translate3d(0, -300px, 0);
	}
}

@keyframes slideOutMobile {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		transform: translate3d(0, 100%, 0);
	}
}

@keyframes slideInRight {
	from {
		transform: translate3d(100%, 0, 0);
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideOutRight {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		transform: translate3d(100%, 0, 0);
	}
}

@keyframes slideInBottom {
	from {
		transform: translate3d(0, 300px, 0);
	}
	to {
		transform: translate3d(0, 0, 0);
	}
}

@keyframes slideOutBottom {
	from {
		transform: translate3d(0, 0, 0);
	}
	to {
		transform: translate3d(0, 300px, 0);
	}
}

@keyframes fadeInUp {
	from {
		transform: translate3d(0, 20px, 0);
		opacity: 0;
	}
	to {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@keyframes pointerEventsDelay {
	0%,99% {
		pointer-events: auto;
	}
	100% {
		pointer-events: none;
	}
}

@keyframes animMarquee {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(calc(-100% - 20px));
    }
}

@keyframes animMarqueeRtl {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
}