.hero {
    height: 689px;
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 90px), 0% 100%);
    @include maxBp($bp-1024) {
        height: 459px;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 23px), 0% 100%);
        margin-bottom: 40px !important; 
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        padding-top: 60px;  
        align-items: flex-start;
        max-width: 880px;

        a.btn {
            min-width: 260px;
        }

        @include maxBp($bp-1024) {
            text-align: center;
            align-items: center;
            padding-top: 30px;
            margin-left: auto;
            margin-right: auto;
            a.btn {
                min-width: 200px;
            }
        }
    }
    &__bg {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        &::after {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(0deg, rgba(0, 76, 128, 0.00) 80.5%, #004C80 100%), rgba(0, 76, 128, 0.60);
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__title {
        @include text-h2;
        margin-bottom: 20px;
        color: var(--white);
        @include maxBp($bp-1024) {
            @include text-h1;
        }
    }
    &__text {
        margin-bottom: 20px;
        color: var(--white);
        @include maxBp($bp-1024) {
            @include text-size-12;
        }
    }
}