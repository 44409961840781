.input-checkbox {
	display: inline-block;
	vertical-align: middle;
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 1;
	cursor: pointer;
	&:hover {
		.input-checkbox__text {
			&:before {
				border-color: var(--gray-500);
			}
		}
	}
	&--active {
		.input-checkbox__text {
			border-color: var(--black);
		}
	}
	input {
		position: absolute;
		z-index: -1;
		left: 0;
		top: 0;
		opacity: 0;
		&:checked {
			~ .input-checkbox__text {
				border-color: var(--black);
				&:before {
					background: var(--grey);
					border-color: var(--grey);
				}
				&:after {
					opacity: 1;
				}
			}
		}

		&[type="radio"] ~ .input-checkbox__text {
			&:before {
				border-radius: 50%;
			}
			&:after {
				@include icon-radio;
				margin: 6px 0 0 6px;
				border-color: var(--gray);
			}
		}

		&[disabled] {
			~ .input-checkbox__text {
				opacity: 0.3;
			}
		}
	}

	&__text {
		display: block;
		position: relative;
		z-index: 1;
		padding: 0 0 0 28px;
		transition: var(--trans);
        @include text-size-12;

		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			left: 0;
			top: 3px;
			width: 18px;
			height: 18px;
			border: 1px solid var(--gray-400);
			transition: var(--trans);
            border-radius: 100%;
		}
		&:after {
			content: '';
            background: url(../images/svg-symbols/check.svg) no-repeat center;
            background-size: contain;
			position: absolute;
			z-index: 1;
			width: 8px;
            height: 6px;
            left: 5px;
            top: 9px;
			opacity: 0;
		}
	}

	&--disabled {
		pointer-events: none;
	}

}