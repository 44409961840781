.about-block {
    display: flex;
    gap: 20px;
    @include maxBp($bp-1024) {
        flex-direction: column;
        gap: 14px;
    }
    &__col {
        flex: 1 1 50%;
        border-radius: 40px;
        overflow: hidden;

        clip-path: polygon( 0% 5.263%,0% 5.263%,0.082% 4.409%,0.319% 3.6%,0.698% 2.844%,1.206% 2.155%,1.831% 1.542%,2.559% 1.015%,3.378% 0.587%,4.275% 0.268%,5.236% 0.069%,6.25% 0%,93.75% 0%,93.75% 0%,94.764% 0.069%,95.725% 0.268%,96.622% 0.587%,97.441% 1.015%,98.169% 1.542%,98.794% 2.155%,99.302% 2.844%,99.681% 3.6%,99.918% 4.409%,100% 5.263%,100% 88.948%,100% 88.948%,99.926% 89.757%,99.713% 90.528%,99.37% 91.252%,98.908% 91.92%,98.338% 92.522%,97.671% 93.047%,96.916% 93.487%,96.085% 93.831%,95.189% 94.07%,94.237% 94.195%,6.737% 99.952%,6.737% 99.952%,5.665% 99.946%,4.641% 99.792%,3.68% 99.503%,2.796% 99.091%,2.006% 98.568%,1.325% 97.946%,0.769% 97.236%,0.352% 96.451%,0.091% 95.603%,0% 94.704%,0% 5.263% ); 

        @include maxBp($bp-1024) {
            border-radius: 30px;
            &--video {
                width: 100%;
                aspect-ratio: 346/412;
                max-height: 500px;
            }
        }
        &--video {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            transition: var(--trans);
            cursor: pointer;
            & > a {
                width: 100%;
                height: 100%;
                position: relative;
                z-index: 2;

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 59, 100, 0.0);
                    transition: var(--trans);
                }
            }
            .icon-play {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%,-50%,0);
            }
            img {
                position: absolute;
                inset: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @include bp($bp-1280) {
                &:hover {
                    & > a {
                        &::after {
                            background-color: rgba(0, 59, 100, 0.1);
                        }
                    }
                    .icon-play {
                        background: var(--blue);
                        .icon-sym {
                            fill: var(--white);
                        }
                    }
                }
            }
        }
        &--text {
            background-color: var(--white);
            padding: 60px 90px;
            @include maxBp($bp-1280) {
                padding: 30px;
            }

            .h2 {
                margin-bottom: 30px;
            }
            .h4 {
                margin-bottom: 20px;
            }

            a.btn {
                margin-left: -30px;
                @include maxBp($bp-1280) {
                    margin-left: -18px;
                }
            }
        }
    }
}

.icon-play {
    width: 210px;
    height: 210px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--white);
    box-shadow: 0px 1px 4px 0px rgba(85, 90, 101, 0.25) inset;
    transition: var(--trans);
    .icon-sym {
        width: 49px;
        height: 56px;
        fill: var(--blue);
        transition: var(--trans);
    }
    @include bp($bp-1280) {
        &:hover {
            background: var(--blue);
            .icon-sym {
                fill: var(--white);
            }
        }
    }

    @include maxBp($bp-1280) {
        width: 70px;
        height: 70px;
        .icon-sym {
            width: 16px;
            height: 19px;
        }
    }
}