.footer{
    padding-top: var(--space-xl);
    padding-bottom: 67px;
    border-radius: 40px 40px 0px 0px;
    background: radial-gradient(77.71% 100.62% at -8.47% -23.73%, #3F93CC 0%, rgba(63, 147, 204, 0.00) 100%), linear-gradient(78deg, rgba(244, 151, 57, 0.00) 49.07%, #F49739 166.49%), linear-gradient(0deg, #F2F2F2 0%, #F2F2F2 100%), #D9D9D9;

    &__top {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(85, 90, 101, 0.30);

        &-item {
            flex: 1 1 auto;
            width: 25%;
        }

        @include maxBp($bp-1280) {
            flex-direction: column;
            align-items: flex-start;
            padding-bottom: 20px;

            &-item {
                width: 100%;
            }
        }
    }
    &__info {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        @include text-size-12;
        .develop {
            display: flex;
            flex-direction: column;
            gap: 8px;
            .icon-sym {
                width: 54px;
                height: 16.002px;
                fill: var(--grey);
            }
            a {
                border-color: transparent;
                color: var(--grey);
                @include bp($bp-1280) {
                    &:hover {
                        color: var(--dark-grey);
                        border-color: var(--dark-grey);
                    }
                }
            }
        }
    }
}

.footer-link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    & > span {
        @include text-size-16;
    }
    & > a {
        @include text-large;
        border: none;
        color: var(--grey);
        @include bp($bp-1280) {
            &:hover {
                color: var(--dark-grey);
            }
        }
    }
}

.footer-menu {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    padding-top: 34px;
    padding-bottom: 40px;

    & > * {
        flex: 1 1 auto;
        width: 25%;
    }

    @include maxBp($bp-1280) {
        flex-direction: column;
        align-items: baseline;
        padding-top: 20px;

        & > * {
            width: 100%;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;

        & > ul {
            li {
                margin-bottom: 12px;
                a {
                    border: none;
                    @include text-title;
                    font-size: 13px;
                    color: var(--dark-grey);
                    @include bp($bp-1280) {
                        &:hover {
                            color: var(--grey);
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        @include maxBp($bp-1280) {
            border-bottom: 1px solid rgba(85, 90, 101, 0.30);
            padding-bottom: 10px;

            & > ul {
                display: none;
            }
        }
    }

    &__subtitle {
        @include text-size-12;
        padding-bottom: 12px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        pointer-events: none;

        .icon {
            box-shadow: 0px 0px 0px 1px rgba(85, 90, 101, 0.30) inset;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            display: none;
            .icon-sym {
                width: 10px;
                height: 10px;
                fill: none;
                transform: rotate(90deg);
                stroke: var(--grey);
                transition: var(--trans);
            }

            @include maxBp($bp-1280) {
                display: flex;
            }
        }

        &.show-toggle {
            .icon {
                .icon-sym {
                    transform: rotate(0deg);
                }
            }
        }

        @include maxBp($bp-1280) {
            pointer-events: all;
            padding-top: 10px;
            padding-bottom: 10px;
            @include text-size-16;
        }
    }
}

.social-list {
    display: flex;
    align-items: center;
    gap: 20px;
    li {
        a {
            display: flex;
            border: none;
            .icon-sym {
                fill: var(--grey);
                width: 24px;
                height: 24px;
                transition: var(--trans);
            }

            @include bp($bp-1280) {
                &:hover {
                    .icon-sym {
                        fill: var(--dark-grey);
                    }
                }
            }
        }
    }
}