.advantages {
    &__header {
        margin-bottom: 56px;
        max-width: 750px;
        h2 {
            margin-bottom: 20px;
        }

        @include maxBp($bp-1024) {
            margin-bottom: 40px;
            h2 {
                margin-bottom: 10px;
            }
        }
    }
    &__item {
        display: flex;
        flex-direction: column;
        height: auto;
        width: calc(calc(100% - 40px) / 3);
        @include maxBp($bp-1024) {
            width: calc(calc(100% - 40px) / 3);
        }
        @include maxBp($bp-768) {
            width: 224px !important;
        }
    }
    &__carusel__wrapper {
        flex-wrap: wrap;
        column-gap: 20px;
        row-gap: 80px;

        @include maxBp($bp-1024) {
            flex-wrap: nowrap;
            gap: 0px;
        }
    }
    &__img {
        display: flex;
        margin-bottom: 24px;
        width: 100px;
        img {
            width: 100%;
        }
        @include maxBp($bp-1024)  {
            width: 60px;
            margin-bottom: 10px;
        }
    }
    &__name {
       @include text-h5;
       color: var(--dark-grey); 
       margin-bottom: 16px;
        @include maxBp($bp-1024) {
            margin-bottom: 10px;
        }
    }
}