.form-select{
	width: auto;
	display: block;
	position: relative;
	color: var(--gray-400);
	select{
		display: none;
		@include maxBp($bp-1280){
			display: block !important;
			position: absolute;
			z-index: 5;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
		}
		&.error{
			~.ss-main{
				border-color: var(--red);
				@include input-error;
				.ss-placeholder{
					color: var(--red);
				}
			}
			~ .form__label {
				@include text-error;
			}
		}
		&:not(.error){
			~.error-message{
				display: none;
			}
		}
		&.filled {
			~ .form__label {
				@include text-size-10;
				transform: translate3d(0, -6px, 0);
			}
		}
	}
	.ss-main{
		display: flex;
		align-items: center;
		transition: var(--trans);
		color: var(--black);
		cursor: pointer;
		background: none;
		border: 1px solid var(--gray-400);
		@include text-size-16;
		border-radius: 0;
		box-shadow: none;
		width: 100%;
		height: auto;
		min-height: 0;
		padding: 0;
		&:hover{
			border-color: var(--gray-500);
			.ss-arrow{
				// stroke: var(--green);
			}
		}
		&.ss-open-below,&.ss-open-above{
			border-color: var(--black);
		}
		.ss-arrow{
			fill: none;
			width: 16px;
			height: 16px;
			transform: rotate(0deg);
			vertical-align: middle;
			margin: 0 16px 0 0;
			path{
				transition: var(--trans);
				stroke: var(--black);
				stroke-width: 10px;
			}
		}
		.ss-values{
			width: 100%;
			padding: 18px 16px 6px 16px;
			height: 50px;
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			@include maxBp($bp-768){
				padding-bottom: 15px;
			}
			.ss-placeholder,.ss-single{
				margin: 0;
				padding: 0;
				display: inline;
				line-height: inherit;
			}
		}
	}
	.ss-content{
		position: absolute;
		z-index: 5;
		left: 0;
		top: 100%;
		height: auto;
		min-width: 100%;
		background: var(--white);
		border: 1px solid var(--black);
		transform: scaleY(0);
		transition: var(--trans);
		transform-origin: center top;
		opacity: 0;
		border-radius: 0;
		@include maxBp($bp-1280){
			display: none;
		}
		&.ss-open-below{
			transform: scaleY(1);
			opacity: 1;
		}
		.ss-list{
			.ss-option{
				cursor: pointer;
				transition: var(--trans);
				padding: 10px 16px;
				background: none;
				&.ss-selected,&:hover,&.ss-highlighted{
					// color: var(--yellow);
					background: none;
				}
				&:first-child, &.ss-hide +.ss-option
				{
					padding-top: 20px;
				}
				&:last-child{
					padding-bottom: 20px;
				}
			}
		}

	}
	.ss-hide{
		display: none;
	}
}