.b-marquee {
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    display: flex;

    &:hover {
        .b-marquee__list {
            animation-play-state: paused;
        }
    }

    &__list {
        animation: animMarquee 20s linear infinite;
        gap: 26px;
        display: flex;
        &.rverce {
            animation-direction: reverse;
        }
        @include maxBp($bp-1280) {
            gap: 18px;
        }
    }
    &__item {
        @include text-title;
        font-weight: 200;
        padding-left: 54px;
        padding-right: 54px;
        position: relative;
        font-size: 66px;
        line-height: 1.25;
        display: inline-block;
        @include maxBp($bp-1280) {
            padding-left: 26px;
            padding-right: 26px;
            font-size: 24px;
        }
        & > * {
            line-height: normal;
            display: flex;
            align-items: center;
        }
        &:after{
			content: '';
			display: block;
			background: url(../images/content/b-marquee.svg) no-repeat 0 0;
			background-size: contain;
			width: 26px;
            height: 52px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate3d(-100%,-50%,0);
            @include maxBp($bp-1280) {
                width: 18px;
                height: 35px;
            }
		}
    }
}