*{
    scrollbar-face-color: var(--blue);
    scrollbar-track-color: var(--gray-200);
    scrollbar-color: var(--blue) var(--gray-200);
    scrollbar-width: thin;
}

.contern-smooth,
.news-carusel__wrapper {
    will-change: transform;
}

html *::-webkit-scrollbar {
    border-radius: 0;
    width: 5px;
    height: 5px;
}
html *::-webkit-scrollbar-thumb {
    background-color: var(--blue);
}
html *::-webkit-scrollbar-track {
    background-color: var(--gray-200);
}

html{
    scroll-behavior: smooth;
    &.scroll-smooth-disabled{
        scroll-behavior: auto;
    }
}
html,
body {
    height: 100%;
}
body {
    background: var(--bg);
    color: var(--grey);
    @include text-body;
}

.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    &__page{
        width: 100%;
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto;
        padding-left: var(--wrapper-space);
        padding-right: var(--wrapper-space);
    }
    &__small{
        max-width: 1000px;
    }
    &__wide{
        width: auto;
        margin-left: calc(-1*var(--wrapper-space));
        margin-right: calc(-1*var(--wrapper-space));
    }
}

.article {
    flex: 1 0 auto;
    @include animation(fadeIn 0.5s var(--cubic));
    padding-bottom: var(--space-m);
    &__top{
        padding-top: calc(124px + 20px);
        @include maxBp($bp-1024){
            padding-top: calc(60px + 20px);
        }
    }
    &__bottom{
        margin-bottom: calc(-1*var(--space-m));
    }
    &__first{
        margin-top: calc(-1 * (100px + var(--space-xl)));
    }
    &--no-space {
        padding-bottom: 0;
        margin-bottom: -58px;
        .read-block {
            position: relative;
            z-index: 2;
        }

        @include maxBp($bp-768) {
            margin-bottom: -24px;
            padding-bottom: 0;
        }
    }

    &--no-space ~ .footer {
        padding-top: 157px;
        border-radius: 0;
        @include maxBp($bp-768) {
            padding-top: 92px;
        }
    }
}

.section {
    position: relative;
    &--after::after,
    &--before::before {
        content: '';
        display: block;
        width: 100%;
        height: 90px;
        background-color: var(--blue);
        position: absolute;
        left: 0;
        right: 0;

        @include maxBp($bp-1280) {
            height: 25px;
        }
    }

    &--after::after {
        top: 99.9%;
        clip-path: polygon(0 0, 0 100%, 100% 0);
    }
    &--before::before {
        bottom: calc(100% - 1px);
        clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    }
}

.footer{
    flex: 0 0 auto;
}

.fade-in{
    @include animation(fadeIn 0.3s var(--cubic));
}
.fade-out{
    @include animation(fadeOut 0.3s var(--cubic));
    opacity: 0;
}
.page-in{
    &.active{
        @include animation(fadeIn 0.7s var(--cubic));
    }
}

[data-src],[data-src-bg]{
    opacity: 0;
    transition:opacity 0.3s var(--cubic);
    &.img-loaded{
        opacity: 1;
    }
}
.mb-after {
    margin-bottom: 190px;
    @include maxBp($bp-1280) {
        margin-bottom: 65px;
    }
}
.mt-after {
    margin-top: 190px;
    @include maxBp($bp-1280) {
        margin-top: 65px;
    }
}


.mb-3xs{
    margin-bottom: var(--space-3xs) !important;
}
.mb-2xs{
    margin-bottom: var(--space-2xs) !important;
}
.mb-xs{
    margin-bottom: var(--space-xs) !important;
}
.mb-s{
    margin-bottom: var(--space-s) !important;
}
.mb-m{
    margin-bottom: var(--space-m) !important;
}
.mb-l{
    margin-bottom: var(--space-l) !important;
}
.mb-xl{
    margin-bottom: var(--space-xl) !important;
}
.mb-2xl{
    margin-bottom: var(--space-2xl) !important;
}
.mb-3xl{
    margin-bottom: var(--space-3xl) !important;
}

.mt-3xs{
    margin-top: var(--space-3xs) !important;
}
.mt-2xs{
    margin-top: var(--space-xs) !important;
}
.mt-xs{
    margin-top: var(--space-xs) !important;
}
.mt-s{
    margin-top: var(--space-s) !important;
}
.mt-m{
    margin-top: var(--space-m) !important;
}
.mt-l{
    margin-top: var(--space-l) !important;
}
.mt-xl{
    margin-top: var(--space-xl) !important;
}
.mt-2xl{
    margin-top: var(--space-2xl) !important;
}
.mt-3xl{
    margin-top: var(--space-3xl) !important;
}


.pb-3xs{
    padding-bottom: var(--space-3xs) !important;
}
.pb-2xs{
    padding-bottom: var(--space-2xs) !important;
}
.pb-xs{
    padding-bottom: var(--space-xs) !important;
}
.pb-s{
    padding-bottom: var(--space-s) !important;
}
.pb-m{
    padding-bottom: var(--space-m) !important;
}
.pb-l{
    padding-bottom: var(--space-l) !important;
}
.pb-xl{
    padding-bottom: var(--space-xl) !important;
}
.pb-2xl{
    padding-bottom: var(--space-2xl) !important;
}
.pb-3xl{
    padding-bottom: var(--space-3xl) !important;
}

.pt-3xs{
    padding-top: var(--space-3xs) !important;
}
.pt-2xs{
    padding-top: var(--space-xs) !important;
}
.pt-xs{
    padding-top: var(--space-xs) !important;
}
.pt-s{
    padding-top: var(--space-s) !important;
}
.pt-m{
    padding-top: var(--space-m) !important;
}
.pt-l{
    padding-top: var(--space-l) !important;
}
.pt-xl{
    padding-top: var(--space-xl) !important;
}
.pt-2xl{
    padding-top: var(--space-2xl) !important;
}
.pt-3xl{
    padding-top: var(--space-3xl) !important;
}

.mb-0{
    margin-bottom: 0 !important;
}
.mt-0{
    margin-top: 0 !important;
}
.pb-0{
    padding-bottom: 0 !important;
}
.pt-0{
    padding-top: 0 !important;
}

.ml-auto {
    margin-left: auto;
}
.mr-auto {
    margin-right: auto;
}

.svg-mask {
    position: absolute;
    z-index: -1;
    left: -100%;
    top: -100%;
}

.tablet-show{
    @include bp($bp-1280){
        display: none !important;
    }
}
.tablet-hide{
    @include maxBp($bp-1280){
        display: none !important;
    }
}
.tablet-vertical-show{
    @include bp($bp-1024){
        display: none !important;
    }
}
.tablet-vertical-hide{
    @include maxBp($bp-1024){
        display: none !important;
    }
}
.mobile-show{
    @include bp($bp-768){
        display: none !important;
    }
}
.mobile-hide{
    @include maxBp($bp-768){
        display: none !important;
    }
}

.mobile-vertical-show{
    @include bp($bp-500){
        display: none !important;
    }
}
.mobile-vertical-hide{
    @include maxBp($bp-500){
        display: none !important;
    }
}

.clear{
    clear: both;
}

.pointer{
    cursor: pointer;
}

.hide{
    display: none !important;
}
