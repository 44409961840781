@font-face {
	font-family: 'Mulish';
	src: local('Mulish Bold'), local('Mulish-Bold'),
	url('../fonts/Mulish-Bold.woff2') format('woff2'),
	url('../fonts/Mulish-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mulish';
	src: local('Mulish ExtraBold'), local('Mulish-ExtraBold'),
	url('../fonts/Mulish-ExtraBold.woff2') format('woff2'),
	url('../fonts/Mulish-ExtraBold.woff') format('woff');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Mulish';
	src: local('Mulish ExtraLight'), local('Mulish-ExtraLight'),
	url('../fonts/Mulish-ExtraLight.woff2') format('woff2'),
	url('../fonts/Mulish-ExtraLight.woff') format('woff');
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Manrope';
	src: local('Manrope Regular'), local('Manrope-Regular'),
	url('../fonts/Manrope-Regular.woff2') format('woff2'),
	url('../fonts/Manrope-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Manrope';
	src: local('Manrope Bold'), local('Manrope-Bold'),
	url('../fonts/Manrope-Bold.woff2') format('woff2'),
	url('../fonts/Manrope-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

