@mixin bp($point) {
    @media only screen and (min-width: #{$point}px) {
        @content;
    }
}

@mixin maxBp($point) {
    @media only screen and (max-width: #{$point - 1}px) {
        @content;
    }
}

@mixin minMaxBp($minPoint, $maxPoint) {
    @media only screen and (min-width: #{$minPoint}px) and (max-width: #{$maxPoint - 1}px) {
        @content;
    }
}
