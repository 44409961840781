.modal {
	display: none;
	&.is-open {
		display: block;
	}
	&__overlay {
		position: fixed;
		left: 0;
		top: 0;
		width: 100%;
		height: calc(100vh - calc(100vh - 100%));
		overflow-x: hidden;
		overflow-y: auto;
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: transparentize($black, 0.7);
		transition: background-color var(--modal-duration) var(--cubic);
		animation: {
			duration: var(--modal-duration);
			timing-function: var(--cubic);
			fill-mode: forwards;
		};
		@include maxBp($bp-768) {
			align-items: flex-end;
		}
		&--block {
			display: block;
		}

		[aria-hidden='false'] &{
			animation-name: shadeIn;
		}
		[aria-hidden='true'] &{
			animation-name: shadeOut;
		}
	}
	&__container {
		width: calc(100% - 10px);
        overflow: hidden;
		max-width: 640px;
		margin: 20px auto;
		box-sizing: border-box;
        border-radius: 40px;
		background: var(--white);
		padding:0 95px 40px 95px;
        box-shadow: 0px 2px 2px 0px rgba(71, 74, 89, 0.02), 0px 2px 8px 0px rgba(71, 74, 89, 0.08);
		@include maxBp($bp-768) {
			margin: 0;
			padding:0 15px 40px 15px;
			max-width: none;
			width: 100%;
		}
		animation: {
			duration: var(--modal-duration);
			timing-function: var(--cubic);
			fill-mode: forwards;
		};
		.modal--small &{
			@include bp($bp-768) {
				max-width: 400px;
			}
		}
		.modal--big &{
			@include bp($bp-768) {
				max-width: 800px;
			}
		}
		[aria-hidden='false'] &{
			animation-name: slideIn;
			@include maxBp($bp-768) {
				animation-name: slideInMobile;
			}
		}
		[aria-hidden='true'] &{
			animation-name: slideOut;
			@include maxBp($bp-768) {
				animation-name: slideOutMobile;
			}
		}
	}
	&__body{
		padding-top: 40px;
		@include maxBp($bp-768) {
			padding-top: 20px;
		}
	}
	&__header {
		padding: 40px 95px 10px 95px;
		margin: 0 -95px 30px -95px;
		background: var(--white);
		position: sticky;
		z-index: 5;
		top: 0;
		@include maxBp($bp-768) {
			padding: 40px 60px 10px 15px;
			margin: 0 -15px 10px -15px;
			text-align: left;
		}
		~.modal__body{
			padding-top: 0;
		}
	}
	&__footer{
		@include text-body;
		padding:30px 65px 40px 65px;
		margin: 30px -65px -65px -65px;
		background: var(--gray-100);
		position: sticky;
		z-index: 5;
		bottom: 0;
		box-shadow:0 -25px 10px -20px var(--white);
		@include maxBp($bp-768) {
			padding: 20px;
			margin: 20px -20px -20px -20px;
		}
	}
	&__close {
		@include icon-close;
		outline: none !important;
		display: inline-block;
		position: absolute;
		z-index: 5;
		right: 20px;
		top: 20px;
	}
	&__bg {
		position: fixed;
		z-index: -1;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	&--gallery{
		.modal__container{
			max-width: none;
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;
		}
		.modal__body{
			height: 100%;
			padding: 0;
		}
	}
	&--cookie{
		.modal__close{
			display: none;
		}
		.modal__header{
			margin-right: 0;
			font-weight: bold;
		}
		&[aria-hidden='false'] .modal__container {
			animation-name: slideInMobile;
		}
		&[aria-hidden='true'] .modal__container {
			animation-name: slideOutMobile;
		}
		.modal__overlay{
			align-items: flex-end;
		}
        .modal__body {
            padding: 0;
            @include text-size-12;
            font-weight: 400;
        }
		.modal__container{
			font-weight: bold;
			text-align: center;
			width: 279px;
            padding: 20px;
            border: 1px solid rgba(85, 90, 101, 0.30);
            border-radius: 30px;
            .btn {
                width: 156px;
            }
			@include maxBp($bp-768){
				width: 100%;
			}
		}
	}
}

.modal-show {
	padding-right: var(--scroll-width);
	position: fixed !important;
	overflow: hidden;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	.header {
		max-width: calc(100% - var(--scroll-width));
	}
}