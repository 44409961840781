@mixin icon-arr {
	content: '';
	display: inline-block;
	vertical-align: middle;
	width: 8px;
	height: 8px;
	border: 2px solid var(--black);
	border-left: none;
	border-bottom: none;
	transform: rotate(45deg);
	transition: var(--trans);
}

@mixin icon-close {
	width: 24px;
	height: 24px;
	border-radius: 50%;
	position: relative;
	z-index: 1;
	margin: -10px;
	border: 10px solid transparent;
	cursor: pointer;
	box-sizing: content-box;
	transform: rotate(45deg);
	transition: var(--trans);
	&:hover {
		&:before, &:after {
			// background: var(--yellow);
		}
	}
	&:before, &:after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 50%;
		top: 50%;
		width: 12px;
		height: 2px;
        border-radius: 10px;
		transform: translate3d(-50%, -50%, 0) scaleX(1.7);
		background: var(--grey);
		transition: var(--trans);
	}
	&:after {
		width: 2px;
		height: 12px;
		transform: translate3d(-50%, -50%, 0) scaleY(1.7);
	}
}

@mixin icon-checkbox {
	content: '';
	display: inline-block;
	vertical-align: middle;
	width: 7px;
	height: 12px;
	border: 1px solid var(--black);
	border-left: none !important;
	border-top: none !important;
	transform: rotate(45deg);
	transition: var(--trans);
	border-radius: 0;
	background: none;
}

@mixin icon-radio {
	content: '';
	display: inline-block;
	vertical-align: middle;
	width: 18px;
	height: 18px;
	background: var(--black);
	transition: var(--trans);
	border-radius: 50%;
}

@mixin icon-plus {
	width: 10px;
	height: 10px;
	position: relative;
	z-index: 1;
	display: inline-block;
	vertical-align: middle;
	&:before, &:after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 50%;
		top: 50%;
		width: 100%;
		height: 2px;
		transform: translate3d(-50%, -50%, 0);
		background: var(--black);
		transition: var(--trans);
	}
	&:after {
		width: 2px;
		height: 100%;
	}
}

@mixin icon-delete {
	display: inline-block;
	width: 12px;
	height: 12px;
	border: 10px solid transparent;
	margin: -10px;
	position: relative;
	z-index: 1;
	cursor: pointer;
	box-sizing: content-box;
	transform: rotate(45deg);
	transition: var(--trans);
	&:hover {
		&:before, &:after {
			background: var(--red);
		}
	}
	&:before, &:after {
		content: '';
		position: absolute;
		z-index: -1;
		left: 50%;
		top: 50%;
		width: 100%;
		height: 2px;
		transform: translate3d(-50%, -50%, 0);
		background: var(--black);
		transition: var(--trans);
	}
	&:after {
		width: 2px;
		height: 100%;
	}
}

@mixin icon-stroke {
	fill: none !important;
	stroke: var(--black);
	stroke-width: 2px;
}
