.btn {
	display: inline-block;
	vertical-align: middle;
	white-space: nowrap;
	transition: var(--trans);
    @include text-title;
	cursor: pointer;
	border: none;
    border-radius: 58px;
	text-align: center;
	overflow: hidden;
	box-shadow: none;
	max-width: 100%;
	text-overflow: ellipsis;
    position: relative;
    &::after {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        background: var(--linear);
        transition: var(--trans);
        opacity: 0;
        z-index: 0;
    }
    & > * {
        position: relative;
        z-index: 2;
    }
    &--border {
        padding: 16px 22px;
        box-shadow: 0px 0px 0px 2px var(--grey) inset;
        color: var(--grey, #555A65);
        @include text-size-16;
        line-height: 1.25;
    }
    &--inside {
        padding: 19px 32px;
        background: transparent;
        box-shadow: 0px 1px 4px 0px rgba(85, 90, 101, 0.25) inset;
        color: var(--grey);
        flex: 0 0 auto;
        @include text-size-14;
        @include maxBp($bp-1280) {
            padding: 13px 32px;
        }
    }
    &--inside-light {
        padding: 19px 32px;
        background: transparent;
        box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.25) inset;
        color: var(--white);
        @include text-size-14;
        @include maxBp($bp-1280) {
            padding: 13px 32px;
            color: var(--white);
        }
    }

    &--light-blue {
        background-color: var(--light-blue);
    }
    &--dark {
        background-color: var(--dark-grey);
    }
    &--light {
        background-color: var(--white);
    }

    &--small {
        padding: 8px 16px 11px 16px;
        @include text-size-14;
    }

    @include bp($bp-1280) {
        &:hover {
            box-shadow: transparent;
            color: var(--white);
            &::after {
                opacity: 1;
            }
        }
    }

	&:active {
		background: var(--linear);
	}
	&--disabled, &[disabled] {
		pointer-events: none;
		cursor: default;
		opacity: 0.5;
	}
	&--100{
		width: 100%;
	}
}