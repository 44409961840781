.gallery{
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	margin: var(--space-s) 0;
	.modal &{
		margin: 0;
	}
	.swiper{
		width: 100%;
		height: auto;
		flex: auto;
	}
	&__item{
		width: 100%;
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		aspect-ratio: 3/2;
		overflow: hidden;
		&:before{
			@extend .loader;
		}
		&[data-micromodal]{
			cursor: pointer;
		}
		[data-swiper-parallax]{
			width: 100%;
			height: 100%;
		}

	}
	iframe{
		aspect-ratio: 16/9;
		width: 100%;
		height: auto;
		max-height: 100%;
		background: var(--black);
		margin:0;
		padding: 0;
		border: none;
		display: block;
		position: absolute;
		z-index: 1;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%,-50%,0);
	}
	&__img{
		width: 100%;
		height: 100%;
		flex: auto;
		background: var(--white);
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		picture{
			display: contents;
		}
		img{
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
			object-fit: contain;
			margin: auto;
			display: block;
		}
	}
	.gallery-thumb.swiper{
		margin-top: 0;
	}
	&--thumb{
		min-height: calc(80px + var(--space-s) + var(--space-s));
		padding: var(--space-s) 0;
		flex:none !important;
		@include maxBp($bp-768){
			min-height: calc(40px + var(--space-s) + var(--space-s));
		}
		.gallery__item{
			width: auto;
			height: 80px;
			margin: 0 10px;
			cursor: pointer;
			transition: opacity 0.3s var(--cubic);
			@include maxBp($bp-768){
				height: 40px;
				margin: 0 5px;
			}
			&.swiper-slide-thumb-active{
				opacity: 0.5;
			}
		}
		.gallery__img{
			background: var(--gray-100);
		}
		.swiper-wrapper{
			justify-content: center;
		}
		&.swiper-center-hide{
			.swiper-wrapper{
				justify-content: normal;
			}
		}
	}
}

.swiper-button-next,
.swiper-button-prev{
	width: 60px;
	height: 80px;
	left: 0;
	top: 50%;
	transform: translate3d(0,-50%,0);
	margin: 0;
	@include maxBp($bp-768){
		display: none;
	}
	&:after{
		color: var(--black);
		font-size: 32px;
		transition: var(--trans-slow);
	}
	&:hover{
		&:after{
			transform: translate3d(-10px,0,0);
		}
	}
}
.swiper-button-next{
	right: 0;
	left: auto;
	&:hover{
		&:after{
			transform: translate3d(10px,0,0);
		}
	}
}
.swiper-pagination-bullet-active{
	background: var(--black);
}
.swiper-zoom-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	position: absolute;
	z-index: 1;
	left: 0;
	top: 0;
}


.carusel-pagination {
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: var(--white);
    display: flex;
    gap: 3px;
    justify-content: center;
    padding-bottom: 2px;
    &--grey {
        .swiper-pagination-bullet {
            background-color: var(--grey);
        }
    }
    &--light {
        .swiper-pagination-bullet {
            background-color: var(--white);
        }
    }
    &.swiper-pagination-bullets {
        gap: 14px;
        .swiper-pagination-bullet {
            transition: var(--trans);
            margin: 0;
        }
    }
}
.swiper-pagination-current {
    display: block;
    min-width: 13px;
}
.carusel-controls {
    display: inline-flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 40px;
    align-self: baseline;
}
.custom-carusel-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    transition: var(--trans);
    box-shadow: 0px 1px 4px 0px rgba(255, 255, 255, 0.25) inset;
    flex: 0 0 auto;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &.swiper-button-disabled {
        pointer-events: none;
        opacity: 0.3;
    }
    &::after {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        background: radial-gradient(116.48% 124.34% at -8.47% -23.73%, #2055E5 0%, rgba(32, 85, 229, 0.00) 100%), linear-gradient(78deg, rgba(255, 129, 2, 0.00) 32.71%, #FF8102 167.05%), linear-gradient(0deg, #FFB571 0%, #FFB571 100%), #D9D9D9;
        opacity: 0;
        transition: var(--trans);
        z-index: 0;
    }
    .icon-sym {
        width: 8px;
        height: 16px;
        fill: none;
        stroke: var(--white);
        position: relative;
        z-index: 1;
    }

    &--prev {
        .icon-sym {
            margin-right: 2px;
        }
    }

    &--next {
        .icon-sym {
            transform: rotate(180deg);
            margin-left: 2px;
        }
    }

    @include bp($bp-1280) {
        &:hover {
            &::after {
                opacity: 1;
            }
        }
    }
}