:root {
	--font-main: 'Manrope', Arial, Helvetica, sans-serif;
    --font-title: 'Mulish', Arial, Helvetica, sans-serif;
	--text-h1-size: 50px;
	--text-h2-size: 64px;
	--text-h3-size: 40px;
	--text-h4-size: 28px;
	--text-h5-size: 20px;
	--text-h6-size: 16px;
	--text-body-size: 16px;
    --text-subtitle: 24px;

	--text-size-10: 10px;
	--text-size-12: 12px;
    --text-size-13: 13px;
	--text-size-14: 14px;
	--text-size-16: 16px;
	--text-size-18: 18px;
	--text-size-20: 20px;
	--text-size-22: 22px;
	--text-size-24: 24px;
    --text-large: 28px;

	@include maxBp($bp-1024) {
		--text-h1-size: 26px;
		--text-h2-size: 28px;
		--text-h3-size: 24px;
		--text-h4-size: 20px;
		--text-h5-size: 16px;
		--text-h6-size: 14px;
		--text-body-size: 14px;
	}
}
