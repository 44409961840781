@mixin reset-list($padding: 0, $margin: 0) {
    padding: $padding;
    margin: $margin;
    list-style: none;
}

@mixin cover($width: 100%, $height: 100%) {
    width: $width;
    height: $height;
    object-fit: cover;
}

@mixin reset-btn {
    padding: 0;
    border: none;
    background-color: transparent;
}

@mixin placeholder {
    &::-webkit-input-placeholder { @content }
    &:-moz-placeholder { @content }
    &::-moz-placeholder { @content }
    &:-ms-input-placeholder { @content }
}

@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content;
    }
    @keyframes #{$name} {
        @content;
    }
}

@mixin animation ($animation) {
    -webkit-animation: $animation;
    animation: $animation;
}

@mixin selected-none{
    outline:none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    *{
        outline:none;
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        user-select: none;
    }
}

@mixin input-error{
    @include animation(shake 0.5s var(--cubic));
}
@mixin text-error{
    @include animation(shakeText 0.5s var(--cubic));
}

