// Base
$black: #000;
$white: #fff;
$blue: #2A87C7;
$light-blue: #3E9BDB;
$red: #FF4545;
$orange: #F49739;
$bg: #F2F2F2;
$grey: #555A65;
$stroke: #C3C8D3;
$dark-grey: #454545;
$linear: var(--2, radial-gradient(132.59% 106.82% at 0% 0%, #2055E5 0%, rgba(32, 85, 229, 0.00) 100%), linear-gradient(87deg, rgba(255, 129, 2, 0.00) 29.02%, #FF8102 145.34%), linear-gradient(0deg, #F49739 0%, #F49739 100%), #D9D9D9);
$gray-100: #F6F6F6;
$gray-200: #e7e7e7;
$gray-300: #D9D9D9;
$gray-400: #838383;
$gray-500: #757575;
$gray-600: #A4A4A4;
$gray-700: #8F8F8F;
$gray-800: #393939;

:root {
    --icon-color: #{$black};
    --white: #{$white};
    --black: #{$black};
    --blue: #{$blue};
    --light-blue: #{$light-blue};
    --red: #{$red};
    --orange: #{$orange};
    --bg: #{$bg};
    --grey: #{$grey};
    --stroke: #{$stroke};
    --dark-grey: #{$dark-grey};
    --linear: #{$linear};
    --gray-100: #{$gray-100};
    --gray-200: #{$gray-200};
    --gray-300: #{$gray-300};
    --gray-400: #{$gray-400};
    --gray-500: #{$gray-500};
    --gray-600: #{$gray-600};
    --gray-700: #{$gray-700};
    --gray-800: #{$gray-800};
}
