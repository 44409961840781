.file-input{
	position: relative;
	@include text-size-16;
	input{
		opacity: 0;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
		&:hover~.file-input__btn{
			border-color: var(--gray-500);
		}
		&:focus~.file-input__btn{
			border-color: var(--black);
		}
		&.error~.file-input__btn{
			border-color: var(--red);
			@include input-error;
		}
	}
	&__btn{
		display: flex;
		align-items: center;
		width: 100%;
		background: var(--white) no-repeat;
		border:1px solid var(--gray-400);
		transition: var(--trans);
		outline: none;
		color: var(--gray-700);
		padding: 12px 16px 14px 16px;
		height: 50px;
		-webkit-appearance:none;
		&:after{
			content: '';
			display: block;
			width: 20px;
			height: 20px;
			min-width: 20px;
			background: url(../images/svg-symbols/file.svg) no-repeat 0 0;
			background-size: contain;
			margin: -10px 0 -10px 10px;
		}
	}
	&__text{
		display: block;
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	&__description{
		opacity: 0.7;
		pointer-events: none;
		@include maxBp($bp-768){
			display: block;
			@include text-size-10;
		}
	}
	&__del{
		position: relative;
		z-index: 1;
		display: none;
		cursor: pointer;
		width: 20px;
		height: 20px;
		color: transparent;
		margin-left: 10px;
		&:before,&:after{
			content: '';
			position: absolute;
			z-index: -1;
			left: 50%;
			top: 50%;
			width: 20px;
			height: 2px;
			background: var(--black);
			margin:-1px 0 0 -10px;
			transform: rotate(45deg);
			transition: var(--trans);
		}
		&:after{
			width: 2px;
			height: 20px;
			margin: -10px 0 0 -1px;
		}
		&--show{
			display: block;
		}
		&:hover{
			&:before,&:after{
				background: var(--red);
			}
		}
	}

	&--change{
		.file-input__btn{
			color: var(--black);
		}
		.file-input__del{
			display: inline-block;
		}
	}

	.error-message{
		top: 100%;
		width: 100%;
		background: var(--white);
	}

}