.form-question {
    padding: 70px 110px;
    background-color: var(--white);
    border-radius: 40px;

    @include maxBp($bp-1024) {
        padding: 30px;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;

        & > * {
            flex: 0 0 auto;
            width: calc(50% - 10px);
            @include maxBp($bp-1024) {
                width: 100%;
            }
        }
    }

    &__col {
        &--text {
            & > * {
                margin-bottom: 20px;
                @include maxBp($bp-1024) {
                    margin-bottom: 10px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            span {
                @include text-size-20;
                display: block;

                @include maxBp($bp-1024) {
                    @include text-size-14;
                }
            }
        }
    }
}