.action {
    &__photo {
        width: 100%;
        overflow: hidden;
        clip-path: polygon(0% 11.331%, 0% 11.331%, 0.131% 9.493%, 0.51% 7.75%, 1.116% 6.124%, 1.929% 4.639%, 2.929% 3.319%, 4.094% 2.186%, 5.404% 1.265%, 6.839% 0.578%, 8.378% 0.148%, 10% 0%, 90% 0%, 90% 0%, 91.622% 0.148%, 93.161% 0.578%, 94.596% 1.265%, 95.906% 2.186%, 97.071% 3.319%, 98.071% 4.639%, 98.884% 6.124%, 99.49% 7.75%, 99.869% 9.493%, 100% 11.331%, 100% 77.249%, 100% 77.249%, 99.89% 78.933%, 99.569% 80.545%, 99.054% 82.064%, 98.358% 83.471%, 97.497% 84.748%, 96.486% 85.875%, 95.339% 86.832%, 94.073% 87.6%, 92.702% 88.16%, 91.24% 88.493%, 11.24% 99.824%, 11.24% 99.824%, 9.475% 99.899%, 7.779% 99.634%, 6.18% 99.057%, 4.705% 98.195%, 3.382% 97.075%, 2.238% 95.726%, 1.3% 94.175%, 0.596% 92.448%, 0.154% 90.574%, 0% 88.58%, 0% 11.331%);
        margin-bottom: 16px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__name {
        @include text-title;
        @include text-size-16;
        color: var(--white);
        margin-bottom: 8px;
        border: none;
        @include bp($bp-1280) {
            &:hover {
                color: var(--orange);
            }
        }
    }
    &__text {
        color: var(--white);
        margin-bottom: 16px;
    }
}

.section-scale {
    background: var(--dark-grey);
    border-radius: 40px;
}