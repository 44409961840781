@mixin text-default() {
    font-family: var(--font-main);
}

@mixin text-title {
    font-family: var(--font-title);
    font-weight: bold;
    line-height: 125%;
    letter-spacing: 0 !important;
}

@mixin text-h1 {
    font-size: var(--text-h1-size);
    line-height: 120%;
    font-weight: 800;
    font-family: var(--font-title);
    letter-spacing: 0;
}

@mixin text-h2 {
    font-size: var(--text-h2-size);
    line-height: 125%;
    font-weight: bold;
    font-family: var(--font-title);
    letter-spacing: 0;
}

@mixin text-h3 {
    font-size: var(--text-h3-size);
    letter-spacing: 0;
    line-height: 125%;
    font-weight: bold;
    font-family: var(--font-title);
}

@mixin text-h4 {
    font-size: var(--text-h4-size);
    line-height: 125%;
    font-weight: bold;
    font-family: var(--font-title);
    letter-spacing: 0;
}

@mixin text-h5 {
    font-size: var(--text-h5-size);
    line-height: 125%;
    font-weight: bold;
    font-family: var(--font-title);
    letter-spacing: 0;
}

@mixin text-h6 {
    font-size: var(--text-h6-size);
    line-height: 125%;
    font-weight: bold;
    font-family: var(--font-title);
    letter-spacing: 0;
}

@mixin text-body {
    @include text-default;
    font-size: var(--text-body-size);
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.5px;
}

@mixin text-size-10 {
    font-size: var(--text-size-10);
    line-height: 150%;
    letter-spacing: 0.5px;
}

@mixin text-large {
    font-size: var(--text-large);
    font-weight: bold;
    line-height: 150%;
    letter-spacing: 0.5px;
}

@mixin text-size-12 {
    font-size: var(--text-size-12);
    line-height: 150%;
    letter-spacing: 0.5px;
}

@mixin text-size-13 {
    font-size: var(--text-size-13);
    line-height: 150%;
    letter-spacing: 0.5px;
}

@mixin text-size-14 {
    font-size: var(--text-size-14);
    line-height: 150%;
    letter-spacing: 0.5px;
}

@mixin text-size-16 {
    font-size: var(--text-size-16);
    line-height: 150%;
    letter-spacing: 0.5px;
}

@mixin text-size-18 {
    font-size: var(--text-size-20);
    line-height: 150%;
    letter-spacing: 0.5px;
}

@mixin text-size-20 {
    font-size: var(--text-size-20);
    line-height: 150%;
    letter-spacing: 0.5px;
}

@mixin text-size-22 {
    font-size: var(--text-size-22);
    line-height: 150%;
    letter-spacing: 0.5px;
}

@mixin text-size-24 {
    font-size: var(--text-size-24);
    line-height: 150%;
    letter-spacing: 0.5px;
}
