.logistic {
    padding-top: 52px;
    background-color: var(--blue);
    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 90px;
        background-color: var(--bg);
        position: absolute;
        left: 0;
        right: 0;
        bottom: -1px;
        clip-path: polygon(100% 0, 0% 100%, 100% 100%);

        @include maxBp($bp-1280) {
            height: 25px;
        }
    }
    & > h2 {
        margin-bottom: 40px;
        @include maxBp($bp-1024) {
            margin-bottom: 20px;
        }
    }
    &__wrapper {
        border-radius: 40px 40px 0 0;
        background: var(--white);
        box-shadow: 0px 1px 4px 0px rgba(42, 135, 199, 0.25) inset;
        padding: 40px 30px;
        @include maxBp($bp-1024) {
            border-radius: 30px 30px 0 0;
            padding: 30px;
        }
    }

    &__carusel {
        padding-bottom: 40px;
        @include maxBp($bp-1024) {
            padding-bottom: 20px;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    &__photo {
        margin-bottom: 16px;
        width: 100%;
        display: flex;
        aspect-ratio: 400/358;
        max-height: 358px;
        clip-path: polygon( 0% 11.331%,0% 11.331%,0.131% 9.493%,0.51% 7.75%,1.116% 6.124%,1.929% 4.639%,2.929% 3.319%,4.094% 2.186%,5.404% 1.265%,6.839% 0.578%,8.378% 0.148%,10% 0%,90% 0%,90% 0%,91.622% 0.148%,93.161% 0.578%,94.596% 1.265%,95.906% 2.186%,97.071% 3.319%,98.071% 4.639%,98.884% 6.124%,99.49% 7.75%,99.869% 9.493%,100% 11.331%,100% 77.249%,100% 77.249%,99.89% 78.933%,99.569% 80.545%,99.054% 82.064%,98.358% 83.471%,97.497% 84.748%,96.486% 85.875%,95.339% 86.832%,94.073% 87.6%,92.702% 88.16%,91.24% 88.493%,11.24% 99.824%,11.24% 99.824%,9.475% 99.899%,7.779% 99.634%,6.18% 99.057%,4.705% 98.195%,3.382% 97.075%,2.238% 95.726%,1.3% 94.175%,0.596% 92.448%,0.154% 90.574%,0% 88.58%,0% 11.331% ); 
        position: relative;
        picture {
            width: 100%;
            display: flex;
        }
        &::after {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 59, 100, 0.30);
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__name {
        margin-bottom: 8px;
        color: var(--dark-color);
    }
    &__text {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical; 
        margin-bottom: 16px;
    }
}