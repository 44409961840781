.breadcrumbs {
    margin-right: calc(-1*var(--wrapper-space));
    overflow-x: auto;
    scrollbar-width: none !important;
    color: var(--white);
    &__list {
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
        padding-right: var(--wrapper-space);
        @include reset-list;
    }

    &__item {
        display: flex;
        align-items: center;
        &:first-child {
            .breadcrumbs__link {
                margin-left: 0;
            }
        }
        &:last-child {
            padding-right: var(--wrapper-space);
        }

        &:not(:first-child)::before {
            content: '/';
            display: block;
            width: 6px;
        }
    }

    &__link {
        border-color: transparent;
        text-decoration: none;
        @include text-size-14;
        margin-left: 23px;
        margin-right: 23px;
        color: var(--white);
        @include maxBp($bp-1024) {
            margin-left: 13px;
            margin-right: 13px;
        }

        &:hover {
            opacity: 0.5;
            color: var(--white);
        }
    }

    span {
        pointer-events: none;
    }

}
