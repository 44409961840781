h1, .h1 {
	@include text-h1;
	&:last-child {
		margin-bottom: 0;
	}
}

h2, .h2 {
	@include text-h2;
	&:last-child {
		margin-bottom: 0;
	}
}

h3, .h3 {
	@include text-h3;
	&:last-child {
		margin-bottom: 0;
	}
}

h4, .h4 {
	@include text-h4;
	&:last-child {
		margin-bottom: 0;
	}
}

h5, .h5 {
	@include text-h5;
	&:last-child {
		margin-bottom: 0;
	}
}

h6, .h6 {
	@include text-h6;
	&:last-child {
		margin-bottom: 0;
	}
}

p, .p {
	padding: 0;
	margin: 0 0 20px 0;
	&:last-child {
		margin-bottom: 0;
	}
}

a {
	text-decoration: none;
	transition: var(--trans);
	cursor: pointer;
	border-bottom: 1px solid var(--blue);
    @include bp($bp-1280) {
        &:hover {
            color: var(--blue);
            border-bottom-color: transparent;
        }
    }
}

.text-nowrap {
	white-space: nowrap;
}

.text-content {
	p {
		padding: 0;
		margin: 0 0 var(--space-xs) 0;
		&:last-child {
			margin-bottom: 0;
		}
		+ img {
			margin-top: var(--space-xs);
		}
		img {
			margin-bottom: var(--space-xs);
		}
	}

	ul, ol {
		margin: var(--space-s) 0;
		&:last-child {
			margin-bottom: 0;
		}
		&:first-child {
			margin-top: 0;
		}
		li {
			padding: 0 0 var(--space-xs) 20px;
			margin: 0;
			position: relative;
			z-index: 1;
			&:last-child {
				padding-bottom: 0;
			}
			&:before {
				content: '';
				position: absolute;
				z-index: -1;
				left: 0;
				top: 10px;
				width: 6px;
				height: 6px;
				border-radius: 50%;
				background: var(--blue);
				@include maxBp($bp-768) {
					top: 8px;
				}
			}
		}

		ul, ol {
			padding-top: var(--space-xs);
			padding-bottom: var(--space-3xs);
		}
	}

	ol {
		counter-reset: list;
		li {
			&:before {
				content: counter(list);
				counter-increment: list;
				width: 20px;
				height: auto;
				background: none;
				border-radius: 0;
				left: 0;
				top: 0;
				font-weight: bold;
			}
		}
	}

	h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
		padding-top: var(--space-3xs);
		&:first-child {
			padding-top: 0;
		}
		+ img {
			margin-top: var(--space-xs);
		}
		+h2,+.h2,+h3,+.h3,+h4,+.h4,+h5,+.h5,+h6,+.h6{
			padding-top: 0;
		}
	}
	hr,.hr {
		display: block;
		width: 100%;
		height: 0;
		margin: var(--space-s) 0;
		padding: 0;
		border: none;
		border-top: 1px solid var(--gray-200);
	}
	cite{
		display: block;
		margin: var(--space-xs) 0;
		font-style: normal;
		position: relative;
		z-index: 1;
		padding-left: 20px;
		&:before{
			content: '* ';
			font-style: normal;
			font-size: 40px;
			line-height: 1;
			position: absolute;
			z-index: 1;
			top: -1px;
			left: 0;
			@include maxBp($bp-768){
				font-size: 36px;
				top: 0;
			}
		}
		&:last-child{
			margin-bottom: 0;
		}
	}
	blockquote {
		display: block;
		position: relative;
		padding: 0 0 0 30px;
		margin: var(--space-xs) 0;
		@include text-size-20;
		font-style: italic;
		color: var(--gray-400);
		border-left: 5px solid var(--gray-200);
		@include maxBp($bp-1440){
			@include text-size-16;
			border-left-width: 2px;
			padding: 0 0 0 20px;
		}
		@include maxBp($bp-768){
			padding-left: 18px;
		}
	}
	mark{
		display: block;
		padding: 0 0 0 25px;
		margin: var(--space-s) 0;
		border-left: 5px solid var(--black);
		background: none;
		@include maxBp($bp-768){
			padding-left: 18px;
			border-left-width: 2px;
		}
	}

	table {
		width: 100%;
		padding: 0;
		margin: var(--space-xs) 0;
		border-collapse: collapse;
		border: 1px solid var(--gray-200);
		@include text-size-14;
		th{
			background: var(--gray-100);
		}
		th, td {
			vertical-align: top;
			text-align: left;
			padding: 10px;
			border-top: 1px solid var(--gray-200);
			&:first-child {
				padding-left: 20px;
			}
			&:last-child {
				padding-right: 20px;
			}
		}
		th {
			font-weight: bold;
		}
	}

	a:not([class]) {
		word-break: break-word;
	}

	iframe {
		width: 100%;
		height: auto;
		display: block;
		border-radius: 10px;
		border: 1px solid var(--gray-200);
		overflow: hidden;
		aspect-ratio: 16/9;
	}

	img{
		display: block;
		width: auto;
		height: auto;
		max-width: 100%;
		margin: var(--space-s) 0;
		+ h1, + h2, + h3, + h4, + h5, + h6, + .h1, + .h2, + .h3, + .h4, + .h5, + .h6 {
			padding-top: 0;
		}
	}
}

.text-light {
    color: var(--bg);
    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
        color: var(--white);
    }
}

.table {
	margin: var(--space-xs) calc(-1*var(--wrapper-space));
	overflow: auto;
	max-width: calc(100% + calc(2*var(--wrapper-space)));
	display: flex;
	&:last-child {
		margin-bottom: 0;
	}
	table{
		margin: 0;
	}
	&:before,&:after{
		content: '';
		display: block;
		min-width: var(--wrapper-space);
	}
}

.text-center {
	text-align: center;
}

.text-bold {
	font-weight: bold;
}

.text-gray{
	color: var(--gray-400);
}

.text-blue{
	color: var(--blue);
}

.text-red{
	color: var(--red);
}