.reviews {
    &__wrapper {
        display: flex;
        flex-direction: column;
    }
    &__item {
        padding: 30px;
        height: auto;
        display: flex;
        align-items: flex-start;
        gap: 20px;
        border-radius: 20px;
        background: var(--white);
        box-shadow: 0px 2px 2px 0px rgba(71, 74, 89, 0.02), 0px 2px 8px 0px rgba(71, 74, 89, 0.08);

        @include maxBp($bp-1280) {
            flex-direction: column;
        }
    }
    &__carusel {
        margin: 0;
    }
    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 140px;
        flex: 0 0 auto;
        color: rgba(69, 69, 69, 0.7);
        @include text-size-16;
        @include maxBp($bp-1280) {
            flex-direction: row;
            align-items: center;
            width: auto;
        }
    }
    &__photo {
        max-width: 100%;
        display: flex;
        margin-bottom: 16px;
        border-radius: 20px;
        overflow: hidden;
        flex: 0 0 auto;
        max-width: 140px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @include maxBp($bp-1280) {
            margin-right: 20px;
            margin-bottom: 0;
        }
    }

    &__name {
        @include text-title;
        font-size: 15px;
        color: var(--dark-grey);
        display: block;
        margin-bottom: 2px;
    }
    &__text__show {
        opacity: 0.7;
    }
    &__block__text {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        &.show {
            display: block;
        }
    }
}

.reviews-carusel {
    padding-top: 100px;
    padding-bottom: 100px;
    background: var(--blue);
    position: relative;
    margin-bottom: 160px;

    @include maxBp($bp-1280) {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &__footer {
        margin-top: 26px;
        display: flex;
        justify-content: center;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 6px;
        h2 {
            margin-bottom: 0;
        }

        @include maxBp($bp-1280) {
            margin-bottom: 26px;
        }
    }

    &__controls {
        @include maxBp($bp-1280) {
            order: 4;
            display: flex;
            justify-content: center;
            margin-bottom: 0;
            margin-top: 26px;
            width: 100%;

            .carusel-pagination {
                display: flex;
                justify-content: center;
            }
        }
    }

    &__links {
        display: flex;
        gap: 12px;
    }
}