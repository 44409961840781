.services {
    & > h2 {
        color: var(--dark-grey);
        margin-bottom: 64px;
        @include maxBp($bp-1280) {
            margin-bottom: 20px;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        @include maxBp($bp-1280) {
            gap: 14px;
        }
    }
    &__item {
        width: calc(calc(100% - 20px) / 2);

        .icon {
            width: 73px;
            height: 73px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #C3C8D3;
            flex: 0 0 auto;
            margin-left: auto;
            background: var(--white);
            transition: var(--trans);
            .icon-sym {
                width: 17px;
                height: 17px;
                fill: none;
                stroke: var(--grey);
            }

            @include maxBp($bp-1024) {
                width: 30px;
                height: 30px;
                .icon-sym {
                    width: 9px;
                    height: 9px;
                }
            }
        }

        @include maxBp($bp-1024) {
            width: 100%;
        }
    }

    &__link {
        border-radius: 20px;
        background: var(--white);
        display: flex;
        align-items: center;
        padding: 20px;
        gap: 20px;
        @include text-size-20;
        position: relative;
        border: none;
        color: var(--grey);
        overflow: hidden;
        &::after {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            width: 100%;
            background: var(--linear);
            opacity: 0;
            transition: var(--trans);
        }

        & > * {
            position: relative;
            z-index: 2;
        }

        @include maxBp($bp-1024) {
            gap: 14px;
            @include text-size-14;
        }

        @include bp($bp-1280) {
            &:hover {
                color: var(--white);
                .icon {
                    border-color: transparent;
                }
                .services__img {
                    img {
                        opacity: 0;
                    }
                    .img__light {
                        opacity: 1;
                    }
                }
                &::after {
                    opacity: 1;
                }
            }
        }
    }
    &__img {
        width: 80px;
        height: 80px;
        padding: 10px;
        display: flex;
        flex: 0 0 auto;
        margin-right: 20px;
        img {
            width: 100%;
            transition: var(--trans);
        }
        .img__light {
            position: absolute;
            top: 50%;
            transform: translate3d(0,-50%,0);
            opacity: 0;
            width: 60px;
            height: 60px;
        }

        @include maxBp($bp-1024) {
            width: 40px;
            height: 40px;
            padding: 5px;
            margin-right: 0;
        }
    }
}