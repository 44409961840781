.news-carusel {
    &__wrapper {
        background: var(--dark-grey);
        border-radius: 40px;
        padding-top: 40px;
        padding-bottom: 40px;
        display: flex;
        flex-direction: column;
        @include maxBp($bp-1024) {
            padding-top: 40px;
            padding-bottom: 40px;
            border-radius: 30px;
        }
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;
        h2 {
            margin-bottom: 0;
        }
        @include maxBp($bp-1280) {
            margin-bottom: 26px;
        }
    }
    &__controls {
        @include maxBp($bp-1280) {
            order: 3;
            width: 100%;
            margin-bottom: 0;
            margin-top: 26px;
        }
    }
}
.news {
    &__carusel {
        margin: 0;
    }
    &__tag {
        display: inline-flex;
        padding: 0 14px;
        padding-bottom: 2px;
        align-items: center;
        height: 28px;
        position: absolute;
        top: 15px;
        left: 15px;
        border-radius: 30px;
        background: var(--orange);
        @include text-h6;
        color: var(--white);
        z-index: 2;
    }
    &__link {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border: none;
        @include bp($bp-1280) {
            &:hover {
                .news__name {
                    color: var(--orange);
                }
                .news__photo {
                    &::after {
                        background-color: rgba(0, 59, 100, 0.40);
                    }
                }
            }
        }
    }

    &__photo {
        margin-bottom: 16px;
        width: 100%;
        display: flex;
        aspect-ratio: 420/358;
        max-height: 358px;
        clip-path: polygon( 0% 11.331%,0% 11.331%,0.131% 9.493%,0.51% 7.75%,1.116% 6.124%,1.929% 4.639%,2.929% 3.319%,4.094% 2.186%,5.404% 1.265%,6.839% 0.578%,8.378% 0.148%,10% 0%,90% 0%,90% 0%,91.622% 0.148%,93.161% 0.578%,94.596% 1.265%,95.906% 2.186%,97.071% 3.319%,98.071% 4.639%,98.884% 6.124%,99.49% 7.75%,99.869% 9.493%,100% 11.331%,100% 77.249%,100% 77.249%,99.89% 78.933%,99.569% 80.545%,99.054% 82.064%,98.358% 83.471%,97.497% 84.748%,96.486% 85.875%,95.339% 86.832%,94.073% 87.6%,92.702% 88.16%,91.24% 88.493%,11.24% 99.824%,11.24% 99.824%,9.475% 99.899%,7.779% 99.634%,6.18% 99.057%,4.705% 98.195%,3.382% 97.075%,2.238% 95.726%,1.3% 94.175%,0.596% 92.448%,0.154% 90.574%,0% 88.58%,0% 11.331% ); 
        position: relative;
        picture {
            width: 100%;
            display: flex;
        }
        &::after {
            content: '';
            display: block;
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 59, 100, 0.30);
            transition: var(--trans);
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: var(--trans);
            display: block;
        }
    }
    &__name {
        color: var(--white);
        margin-bottom: 8px;
        transition: var(--trans);
    }
    &__text {
        margin-bottom: 8px;
        @include text-size-14;
        color: var(--bg)
    }
    &__date {
        @include text-size-13;
        font-weight: 700;
        color: var(--orange);
    }
}

.test-section {
    height: 100vh;
    position: relative;
}