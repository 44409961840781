.header{
	position: absolute;
	z-index: 100;
	left: 0;
	top: 0;
	width: 100%;
	transition: var(--trans);
    &--fixed {
        animation: 1s alternate slideIn forwards;
        position: fixed;
    }
	&__logo{
        width: 59.781px;
        height: 47px;
		border: none;
		display: flex;
        margin-right: 40px;
        position: relative;
		&__img{
			width: 100%;
			height: auto;
			display: block;
            transition: var(--trans);
            &--dark {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
            }
		}

        @include maxBp($bp-1280) {
            width: 42px;
            height: 31.448px;
        }
	}
	&__block{
		display: flex;
		align-items: center;
		flex: auto;
        height: 100%;
        margin-right: 20px;
        @include maxBp($bp-1280) {
            width: 100%;
            flex-direction: column;
            margin-right: 0;
        }
	}

    &__close-menu {
        position: sticky;
        padding: 15px;
        right: 0;
        top: 0px;
        border: none;
        display: inline-block;
        width: 50px;
        left: 100%;
        z-index: 99;
        // transform: translate3d(0,-100%,0);
        .icon-sym {
            width: 22px;
            height: 16px;
            fill: none;
            stroke: var(--grey);
        }
    }

    &--light {
        .header-mobile {
            border-bottom: 1px solid var(--white, #FFF);
            &__wrapper {
                background: transparent;
            }
        }
        .header-desc__bottom {
            background: transparent;
            border-bottom: 1px solid var(--white);
        }

        .header-menu {
            & > li {
                & > a {
                    &:hover {
                        color: var(--light-blue);
                    }
                }
            }
        }
    }
    &.color-bg,
    &--fixed {
        .header-desc__bottom {
            background-color: var(--white);
            border-bottom: 1px solid rgba(85, 90, 101, 0.30);

            .btn--inside-light {
                box-shadow: 0px 1px 4px 0px rgba(85, 90, 101, 0.25) inset;
                color: var(--grey);
                &:hover {
                    color: var(--white);
                }
            }
        }
        .header__logo {
            &__img {
                &--light {
                    opacity: 0;
                }
                &--dark {
                    opacity: 1;
                }
            }
        }
        .header-search__open {
            .icon-sym {
                stroke: var(--grey);
            }
        }
        .header-menu {
            &__name {
                & > a {
                    color: var(--grey);
                }
                .icon-sym {
                    stroke: var(--grey);
                }
            }
            &__sublist {
                .header-menu__name {
                    & > a {
                        color: var(--grey);
                    }
                }
                &:hover {
                    .header-menu__name {
                        & > a {
                            color: var(--blue);
                        }
                        .icon-sym {
                            stroke: var(--blue);
                        }
                    }
                }
            }
            & > li {
                & > a {
                    color: var(--grey);
                }
            }
        }
        .header-mobile {
            &__wrapper {
                background: var(--blue);
            }
        }
    }
}

.header-desc {
    display: flex;
    flex-direction: column;
    &__top {
        height: 40px;
        gap: 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        background-color: var(--white);
        border-bottom: 1px solid rgba(85, 90, 101, 0.30);
    }

    &__bottom {
        display: flex;
        align-items: center;
        height: 83px;
        background: var(--blue);
        transition: var(--trans);
    }

    @include maxBp($bp-1280) {
        transition: var(--trans);
        transform-origin: top;
        transform: scaleY(0);
        position: fixed;
        width: 100vw;
        height: 100dvh;
        top: 0;
        padding-top: 19px;
        padding-bottom: 43px;
        overflow-y: auto;
        clip-path: polygon( 0% 6.607%,0% 6.607%,0.105% 5.968%,0.408% 5.359%,0.894% 4.788%,1.549% 4.263%,2.357% 3.791%,3.301% 3.38%,4.368% 3.039%,5.541% 2.774%,6.805% 2.594%,8.145% 2.505%,93.942% 0.037%,93.942% 0.037%,94.91% 0.048%,95.833% 0.132%,96.699% 0.285%,97.493% 0.499%,98.203% 0.768%,98.814% 1.087%,99.312% 1.45%,99.685% 1.851%,99.919% 2.282%,100% 2.74%,100% 2.74%,100% 2.74%,100% 93.393%,100% 93.393%,99.895% 94.032%,99.592% 94.641%,99.106% 95.212%,98.451% 95.737%,97.643% 96.209%,96.699% 96.62%,95.632% 96.961%,94.459% 97.226%,93.195% 97.406%,91.855% 97.495%,6.058% 99.963%,6.058% 99.963%,5.09% 99.952%,4.167% 99.868%,3.301% 99.715%,2.507% 99.502%,1.797% 99.232%,1.186% 98.913%,0.688% 98.55%,0.315% 98.149%,0.081% 97.718%,0% 97.26%,0% 97.26%,0% 6.607% ); 
        background-color: var(--white);

        &__bottom {
            background-color: transparent;
            height: auto;
            .header__logo {
                display: none;
            }
        }

        &__top {
            background-color: transparent;
            order: 2;
            flex-direction: column;
            align-items: flex-start;
            height: auto;
            border: none;
            padding-top: 26px;
            padding-bottom: 0;
            gap: 26px;
        }
    }
}

.header-link {
    display: flex;
    align-items: center;
    gap: 10px;
    border: none;
    @include text-size-14;
    color: var(--grey);
    .icon-sym {
        width: 16px;
        height: 16px;
        fill: none;
        stroke: var(--grey);
        transition: var(--trans);
        &--placemark {
            width: 20px;
            height: 20px;
        }
    }

    @include bp($bp-1280) {
        &:hover {
            color: var(--blue);
            .icon-sym {
                stroke: var(--blue);
            }
        }
    }

    @include maxBp($bp-1280) {
        &.ml-auto {
            margin-left: 0 !important;
        }
    }
}

.headder-language {
    display: flex;
    width: 53px;
    height: 26px;
    background-color: var(--bg);
    border-radius: 250px;
    border: 1px solid var(--bg);
    @include maxBp($bp-1280) {
        width: auto;
        height: auto;
    }

    a { 
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 24px;
        height: 24px;
        text-transform: uppercase;
        @include text-size-12;
        line-height: 20px;
        padding: 2px 4px;
        border-radius: 100%;
        border: none;
        position: relative;
        color: var(--grey);
        span {
            position: relative;
            z-index: 1;
        }
        @include maxBp($bp-1280) {
            width: 38px;
            height: 38px;
        }
        &.active {
            background-color: var(--orange);
            color: var(--white);
        }
    }

    &__ru,
    &__en {
        &:not(&.active) {
            &::after {
                content: '';
                width: 100%;
                height: 100%;
                display: block;
                background-color: var(--orange);
                opacity: 0.3;
                position: absolute;
                top: 0;
                border-radius: 100%;
                z-index: 0;
                transition: var(--trans);
            }
        } 
    }
    &__en {
        &::after {
            transform: translate3d(-100%,0,0);
        }
        &:not(&.active) {
            &:hover {
                color: var(--black);
                &::after {
                    transform: translate3d(0,0,0); 
                }
            }
        }
    }
    &__ru {
        &::after {
            transform: translate3d(100%,0,0);
        }
        &:not(&.active) {
            &:hover {
                color: var(--black);
                &::after {
                    transform: translate3d(0,0,0); 
                }
            }
        }
    }
}


.header-menu{
	display: flex;
	gap: 10px;
    width: 100%;
    justify-content: space-between;
    margin-right: 40px;
    &__sublist {
        cursor: pointer;
        ul {
            display: flex;
            align-items: center;
            position: absolute;
            top: 100%;
            width: 100%;
            transform: translate3d(0,-10px,100px);
            left: 0;
            padding: 20px 170px;
            border-radius: 0px 0px 40px 40px;
            border-bottom: 1px solid rgba(85, 90, 101, 0.30);
            background: var(--white, #FFF);
            row-gap: 20px;
            column-gap: 46px;
            opacity: 0;
            transition: var(--trans);
            pointer-events: none;
            z-index: -1;
            > li {
                position: relative;
                width: min-content;
                &::after {
                    content: '/';
                    display: block;
                    position: absolute; 
                    @include text-size-14;
                    color: var(--grey);
                    transform: translate(0, -50%);
                    top: 50%;
                    right: -26px;
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }
                > a {
                    display: inline-flex;
                    @include text-size-14;
                    color: var(--grey);
                    border: none;
                    transition: var(--trans);
                    max-width: 150px;
                    @include bp($bp-1280) {
                        &:hover {
                            color: var(--blue);
                        }
                    }

                }
            }

            @include maxBp($bp-1280) {
                display: none;
                position: static;
                top: auto;
                transform: translate3d(0,0,0);
                opacity: 1;
                z-index: 0;
                padding: 0 0 0 10px;
                border: none;
                flex-direction: column;
                align-items: flex-start;
                gap: 0;
                border-radius: 0;
                >li {
                    width: 100%;
                    padding-top: 6px;
                    padding-bottom: 6px;
                    &:last-child {
                        padding-bottom: 0;
                    }
                    >a {
                        max-width: none;
                        width: 100%;
                    }
                    &::after {
                        display: none;
                    }
                }
            }
        }
        @include bp($bp-1280) {
            &:hover {
                .icon-sym {
                    transform: rotate(180deg);
                }

                & > ul {
                    transition-delay: .1s;
                    opacity: 1;
                    pointer-events: all;
                    transform: translate3d(0,0,0);
                }
            }
        }

        @include maxBp($bp-1280) {
            flex-direction: column;
            align-items: flex-start;
        }

    }
    &__name {
        height: 100%;
        transition: var(--trans);
        display: inline-block;

        .icon-sym {
            width: 16px;
            height: 16px;
            stroke: var(--white);
            fill: none;
            flex: 0 0 auto;
            transition: var(--trans);

            @include maxBp($bp-1280) {
                stroke: var(--grey);
            }
        }

        @include maxBp($bp-1280) {
            height: auto;

            &.show-toggle {
                font-weight: 700;
                .icon-sym {
                    transform: rotate(180deg);
                }
            }
        }
    }
	>li{
		& > a,
        & > .header-menu__name > a {
			color: var(--white);
            @include text-size-14;
			display: flex;
			align-items: center;
			white-space: nowrap;
			min-height: 83px;
			z-index: 1;
			border: none;
			overflow: hidden;
            gap: 4px;
			@include maxBp($bp-1280){
				min-height: 0;
                color: var(--grey);
			}
		}

        @include maxBp($bp-1280) {
            margin-top: 12px;
            &:first-child {
                margin-top: 0;
            }
        }
	}

    @include maxBp($bp-1280) {
        flex-direction: column;
        margin-right: 0;
        gap: 0;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(85, 90, 101, 0.3);
    }
}

.header-burger{
    padding: 14px;
    cursor: pointer;
    margin-right: -14px;
    .icon-sym {
        width: 22px;
        height: 16px;
        stroke: var(--white);
    }
}

.header-search {
    height: 46px;
    display: flex;
    position: relative;
    &__open {
        padding: 4px;
        width: 30px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .icon-sym {
            width: 22px;
            height: 22px;
            fill: none;
            stroke: var(--white);
            transition: var(--trans);
        }
    }

    &__search,
    &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 44px;
        border-radius: 50%;
        position: absolute;
        left: 1px;
        top: 1px;
        @include reset-btn;
        cursor: pointer;

        .icon-sym {
            width: 22px;
            height: 22px;
            fill: none;
            stroke: var(--white);
            transition: var(--trans);
        }

        @include maxBp($bp-1280) {
            .icon-sym {
                stroke: var(--grey);
            }
        }
    }

    &__close {
        left: auto;
        right: 1px;
        transition: var(--trans);
    }

    &__wrapper {
        width: 0;
        position: absolute;
        opacity: 0;
        top: 0;
        right: 0;
        overflow: hidden;
        height: 100%;
        transition: var(--trans-slow);

        .form--search {
            height: 100%;
        }

        @include maxBp($bp-1280) {
            width: 100%;
            opacity: 1;
        }
    }

    &.open {
        .header-search__wrapper {
            width: calc(100vw - 445px);
            opacity: 1; 
            z-index: 2;
        }
    }
    @include maxBp($bp-1280) {
        width: 100%;
        margin-top: 20px;
    }
}

.menu-show {
	@include maxBp($bp-1280) {
		padding-right: var(--scroll-width);
		position: fixed !important;
		overflow: hidden;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		.header{
			height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
            .header-mobile {
                opacity: 0;
            }
		}

        .header-desc {
            display: flex;
            transform: scaleY(1);
        }
	}
}

.header-mobile {
    height: 60px;
    display: none;
    transition: var(--trans);

    &__wrapper {
        display: flex;
        align-items: center;
        background-color: var(--blue);
        transition: var(--trans);
    }
    &__logo {
        width: 42px;
        height: 31.448px;
        margin-right: auto;
        border: none;
    }
    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        padding: 14px;
        .icon-sym {
            width: 22px;
            height: 22px;
            fill: none;
            stroke: var(--white);
        }
    }

    @include maxBp($bp-1280) {
        display: flex;
    }
}