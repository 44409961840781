@keyframes loader {
	from{
		transform: translate3d(0,100%,0) rotate(0);
	}
	to{
		transform: translate3d(0,100%,0) rotate(360deg);
	}
}

.loader{
	content: '';
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background:conic-gradient(#0000 10%,#000);
	mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
	-webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 4px),#000 0);
	animation:loader 1s infinite linear;
	position: absolute;
	z-index: -1;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%,-50%,0);
}