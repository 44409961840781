.question {
    padding-top: var(--space-l);
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 64px;
        h2 {
            margin-bottom: 0;
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    @include maxBp($bp-1280) {
        padding-top: 0;
        &__header {
            margin-bottom: 20px;
        }
    }
}

.question-list {
    display: flex;
    flex-direction: column;
    &__item {
        border-bottom: 1px solid var(--Neutral-border, #D7DAE2);
    }

    &__name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include text-h4;
        padding: 20px 0;
        cursor: pointer;
        color: var(--dark-grey);
        gap: 16px;
        transition: var(--trans);

        .icon {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;

            width: 73px;
            height: 73px;
            border-radius: 100%;
            border: 1px solid #C3C8D3;
            transition: var(--trans);
            position: relative;
            overflow: hidden;
            &::before {
                content: '';
                display: block;
                width: 100%;
                position: absolute;
                inset: 0;
                background: var(--linear);
                opacity: 0;
                transition: var(--trans);
            }

            @include maxBp($bp-1280) {
                width: 55px;
                height: 55px;
            }

            .icon-sym {
                width: 16px;
                height: 16px;
                transform: rotate(90deg);
                stroke: #555A65;
                fill: none;
                transition: var(--trans);
            }
        }

        &.show-toggle {
            .icon-sym {
                transform: rotate(0deg);
            }
        }

        @include maxBp($bp-1280) {
            @include text-h5;
        }

        @include bp($bp-1280) {
            &:hover {
                color: var(--grey);
                .icon {
                    &::before {
                        opacity: 1;
                    }
                    .icon-sym {
                        stroke: var(--white);
                    }
                }
            }
        }
    }

    &__text {
        & > * {
            &:first-child {
                padding-top: 4px;
            }
        }
        display: none;
        padding-bottom: 20px;
    }
}