.form {
	&__line {
		position: relative;
		margin: 0 0 20px 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
    &__grid {
        display: flex;
        flex-wrap: wrap;
        column-gap: 20px;
        .form__line {
            flex: 0 0 auto;
            width: 100%;
            &--50 {
                width: calc(calc(100% - 20px) / 2);
            }
            &--33 {
                width: calc(calc(100% - 40px) / 3);
            }
            &--25 {
                width: calc(calc(100% - 60px) / 4);
            }

            @include maxBp($bp-1024) {
                &--50,
                &--33,
                &--25 {
                    width: 100%;
                }
            }
        }
    }
	&__label {
		color: var(--gray);
		@include text-size-16;
		padding: 16px 28px;
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		pointer-events: none;
		border: 1px solid transparent;
		transition: var(--trans);
		margin: 0;
		white-space: nowrap;
		overflow: hidden;
		max-width: 100%;
		text-overflow: ellipsis;
        @include maxBp($bp-1280) {
            @include text-size-14;
            padding: 11px 20px;
        }
	}

	&__input {
		display: block;
		width: 100%;
        height: 58px;
		outline: 0;
		@include text-size-16;
		margin: 0;
		padding: 16px 27px;
		transition: var(--trans);
		box-shadow: none;
		overflow: auto;
		resize: none;
        border-radius: 48px;
        border: 1px solid #DEDEDE;
        background: var(--white);
		&:hover {
			border-color: #C0C0C0;
		}
		&:focus {
			border-color: var(--grey);
		}
		&.error {
            border: 1px solid #7D3C3C;
            background: linear-gradient(0deg, rgba(125, 60, 60, 0.10) 0%, rgba(125, 60, 60, 0.10) 100%), #FFF;
			@include input-error;
			~ .form__label {
				@include text-error;
			}
		}
        &:focus, &.filled {
            ~ .form__label {
                @include text-size-10;
                transform: translate3d(0,-12px,0);
            }
        }
        &[required] ~ .form__label {
            &::after {
                content: '*';
                color: #F55D6D;
                opacity: 0.7;
            }
        }
		&:not(.error) + .error-message {
			display: none;
		}
		@include placeholder {
			color: rgba(85, 90, 101, 0.4);
		}
        @include maxBp($bp-1280) {
            padding: 12px 19px;
            height: 47px;
            @include text-size-14;
        }

        &--search-header {
            height: 46px;
            padding: 10px 50px 10px 53px;
            border-radius: 30px;
            border: 1px solid rgba(255, 255, 255, 0.30);
            background: var(--blue);
            color: var(--white);
            &:hover {
                border: 1px solid rgba(255, 255, 255, 0.50);
            }
            &:focus {
                border: 1px solid rgba(255, 255, 255, 0.80);
            }
            @include placeholder {
                color: rgba(255, 255, 255, 0.5);
            }
            @include maxBp($bp-1280) {
                padding: 10px 10px 10px 47px;
                @include text-size-16;
                border: 1px solid rgba(85, 90, 101, 0.30);
                background: var(--bg);
                color: var(--gray);

                &:hover {
                    border-color: #C0C0C0;
                }
                &:focus {
                    border-color: var(--grey);
                }

                @include placeholder {
                    color: rgba(85, 90, 101, 0.5);
                }
            }
        }
	}
	textarea {
		min-height: 100px;
        border-radius: 29px;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	input[type=number] {
		-moz-appearance: textfield;
	}
	&--disabled {
		opacity: 0.5;
		pointer-events: none;
	}
	&__submit{
		padding-top: 20px;
        &--center {
            display: flex;
            justify-content: center;
        }
	}
	&__text{
		display: block;
		color: var(--gray-400);
		@include text-size-10;
	}
}

.error-message {
	display: block;
	color: #7D3C3C;
	@include text-size-12;
    left: 28px;
	white-space: nowrap;
	order: 1;
	position: absolute;
	.form-select &{
		top: 100%;
	}
}

.form-message-success,
.form-message-error{
	@include animation(fadeIn 0.3s var(--cubic));
}
