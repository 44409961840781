.read-block {
    padding-top: 20px;
    padding-bottom: var(--space-l);
    border-radius: 40px;
    background-color: var(--dark-grey);
    @include maxBp($bp-1024) {
        border-radius: 30px;
    }
    h2.h2 {
        margin-bottom: var(--space-xs);
    }
    &__grid {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-top: 35px;
        & > * {
            flex: 0 0 auto;
            width: calc(50% - 10px);
            @include maxBp($bp-1024) {
                width: 100%;
            }
        }
    }
    &__text {
        overflow: hidden;
        position: relative;
        transition: var(--trans);
        &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(180deg, rgba(69, 69, 69, 0.00) 0%, #454545 96.13%);
            transition: var(--trans);
            pointer-events: none;
            opacity: 0;
        }
        &.hidden-text {
            max-height: 110px;
            &::after {
                opacity: 1;
            }
        }
        &.hidden-text ~ .read-block__more {
            display: inline-block;
            opacity: 0.7;
        }
    }
    &__more {
        display: none;
        opacity: 0;
        cursor: pointer;
        color: var(--white);
        border-color: var(--white);
        transform: translate3d(0,-100%,0);
        transition: var(--trans);
    }
}