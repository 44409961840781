.main-numbers {
    display: flex;
    align-items: center;
    margin-left: -10px;
    margin-right: -10px;
    @include maxBp($bp-1024) {
        flex-direction: column;
        align-items: flex-start;
        margin-left: 0;
        margin-right: 0;
        gap: 40px;
    }
    &__item {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        @include maxBp($bp-1024) {
            width: 100%;
        }
    }
    &__top {
        display: flex;
        align-items: flex-end;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 13px;
        border-bottom: 1px solid #C3C8D3;
        @include maxBp($bp-1024) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__bot {
        padding-left: 10px;
        padding-right: 10px;
        @include text-size-16;
        @include maxBp($bp-1024) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__text {
        @include text-title;
        font-size: 27px; 
        line-height: 1;
    }
    &__caption {
        @include text-title;
        font-size: 96px;
        line-height: 1;
        margin-bottom: -8px;
        @include maxBp($bp-1024) {
            font-size: 70px;
            margin-bottom: -4px;
        }
        &:after{
			content: attr(data-suffix);
		}
        &::before {
            content: attr(data-preffix);
        }
    }
}