$space-3xs: 6px;
$space-3xs-desktop: 6px;
$space-3xs-mobile: 4px;

$space-2xs: 10px;
$space-2xs-desktop: 10px;
$space-2xs-mobile: 6px;

$space-xs: 20px;
$space-xs-desktop: 20px;
$space-xs-mobile: 14px;

$space-s: 30px;
$space-s-desktop: 30px;
$space-s-mobile: 20px;

$space-m: 40px;
$space-m-desktop: 40px;
$space-m-mobile: 26px;

$space-l: 70px;
$space-l-desktop: 70px;
$space-l-mobile: 40px;

$space-xl: 100px;
$space-xl-desktop: 100px;
$space-xl-mobile: 70px;

$space-2xl: 140px;
$space-2xl-desktop: 140px;
$space-2xl-mobile: 100px;

$space-3xl: 140px;
$space-3xl-desktop: 140px;
$space-3xl-mobile: 100px;

$vh-100: 100vh;

$wrapper-space: 70px;
$wrapper-space-desktop: 70px;
$wrapper-space-mobile: 15px;

:root {
    --space-3xs: #{$space-3xs};
    --space-2xs: #{$space-2xs};
    --space-xs: #{$space-xs};
    --space-s: #{$space-s};
    --space-m: #{$space-m};
    --space-l: #{$space-l};
    --space-xl: #{$space-xl};
    --space-2xl: #{$space-2xl};
    --space-3xl: #{$space-3xl};
    --vh-100: #{$vh-100};
    --wrapper-space: #{$wrapper-space};
    @include maxBp($bp-1440){
        --space-3xs: #{$space-3xs-desktop};
        --space-2xs: #{$space-2xs-desktop};
        --space-xs: #{$space-xs-desktop};
        --space-s: #{$space-s-desktop};
        --space-m: #{$space-m-desktop};
        --space-l: #{$space-l-desktop};
        --space-xl: #{$space-xl-desktop};
        --space-2xl: #{$space-2xl-desktop};
        --space-3xl: #{$space-3xl-desktop};
        --wrapper-space: #{$wrapper-space-desktop};
    }
    @include maxBp($bp-1024){
        --space-3xs: #{$space-3xs-mobile};
        --space-2xs: #{$space-2xs-mobile};
        --space-xs: #{$space-xs-mobile};
        --space-s: #{$space-s-mobile};
        --space-m: #{$space-m-mobile};
        --space-l: #{$space-l-mobile};
        --space-xl: #{$space-xl-mobile};
        --space-2xl: #{$space-2xl-mobile};
        --space-3xl: #{$space-3xl-mobile};
        --wrapper-space: #{$wrapper-space-mobile};
    }
}
