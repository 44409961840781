.partners {
    background: var(--blue);
    &__carusel__wrapper {
        gap: 40px;
        flex-wrap: wrap;

        & > * {
            width: calc(calc(100% - 160px) / 5);
        }

        @include maxBp($bp-1024) {
            flex-wrap: nowrap;
            & > * {
                width: 106px !important;
            }
        } 
    }
    &__name {
        transition: var(--trans);
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        overflow: hidden;
        height: 120px;
        background: var(--blue);
        transition: var(--trans);

        @include text-h5;
        color: var(--white);
        cursor: pointer;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-60%,-40%,0) scale(0.8);
            opacity: 0;
            transition: var(--trans);
        }

        @include bp($bp-1280) {
            &:hover {
                .partners__name {
                    opacity: 0;
                }
                background: var(--white);
                img {
                    opacity: 1;
                    transform: translate3d(-50%,-50%,0) scale(1);
                }
            }
        }
    }
}